import { getApiUrl } from '../_helpers';

export const msgService = {
    grouplist
  , groupcreate
  , assigngrouprooms
  , getgrouprooms
  , deactivateGroup
  , send
  , getAllActiveFromSite
  , getMessageDetailsFromSite
  , getActiveFromSiteForRoom
  , deactivateMessageFromSite
};

function grouplist(siteId) {
  var url = getApiUrl() + 'site/msg/group/list/' + siteId;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 404) {
          reject("There was an error looking for this site's groups.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function groupcreate(data) {
  var url = getApiUrl() + 'site/msg/group/create';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 404) {
          reject("There was an error creating the group.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function assigngrouprooms(data) {
  var url = getApiUrl() + 'site/msg/group/assignrooms';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 404) {
          reject("There was an error creating the group.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function getgrouprooms(groupid) {
  var url = getApiUrl() + 'site/msg/group/rooms/' + groupid;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else if (response.status === 404) {
        reject("There was an error looking for the selected group's rooms.");
      }
      else {
        reject(response.statusText);
      }
    })
    .catch(response => {
      reject(response.statusText);
    });
  });
}

function deactivateGroup(groupid) {
  var url = getApiUrl() + 'site/msg/group/deactivate/' + groupid;

  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include'
    })
    .then(response => {
      if (response.status === 200) {
        resolve(response.json().then(data => data));
      }
      else if (response.status === 404) {
        reject("There was an error deactivating the selected group.");
      }
      else {
        reject(response.statusText);
      }
    })
    .catch(response => {
      reject(response.statusText);
    });
  });
}

function send(data) {
  var url = getApiUrl() + 'site/msg/send';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        }
        else if (response.status === 404) {
          reject("There was an error sending the message.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function getAllActiveFromSite(data) {
  var url = getApiUrl() + 'site/msg/active';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          if (response.json.length > 0)
            resolve(response.json().then(data => data));
          else resolve([]);
        }
        else if (response.status === 404) {
          reject("There was an error getting active messages.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function getMessageDetailsFromSite(data) {
  var url = getApiUrl() + 'site/msg/active/message';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 404) {
          reject("There was an error getting message details.");
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function getActiveFromSiteForRoom(data) {
  var url = getApiUrl() + 'site/msg/room/active';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.json().then(data => data));
        }
        else if (response.status === 404) {
          reject("There was an error getting active messages for room" + data.room);
        }
        else {
          reject(response.statusText);
        }
      })
      .catch(response => {
        reject(response.statusText);
      });
  });
}

function deactivateMessageFromSite(data) {
  var url = getApiUrl() + 'site/msg/deactivate';

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        credentials: 'include'
      })
        .then(response => {
          if (response.status === 200) {
            resolve(response);
          }
          else if (response.status === 404) {
            reject("There was an error deactivating the message.");
          }
          else {
            reject(response.statusText);
          }
        })
        .catch(response => {
          reject(response.statusText);
        });
    });
}
